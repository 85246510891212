export function handelCopyText() {
  const textToCopy = document.querySelectorAll(".textToCopy");
  if (textToCopy && textToCopy.length > 0) {
    textToCopy.forEach((el) => {
      el.addEventListener("click", (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(event.target.outerText);
        event.srcElement.setAttribute("data-tooltip", "Copied");
        setTimeout(() => {
          event.srcElement.removeAttribute("data-tooltip");
        }, 1000);
      });
    });
  }
}
