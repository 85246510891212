export function handleSliderNavigation() {
  function addSliderNavigation({ controlElem, contentElem }) {
    const sliderControls = document.querySelectorAll(controlElem);
    const sliderContent = document.querySelector(contentElem) as HTMLElement;

    sliderControls.forEach((control, index) => {
      const sliderContentChildren = Array.from(sliderContent.children);
      if (index === 0) {
        control.classList.add("active");
        sliderContentChildren[0].classList.add("active");
      }

      control.addEventListener("click", () => {
        sliderContent.style.setProperty(
          "--active-slider-index",
          index.toString()
        );
        sliderControls.forEach((control) => control.classList.remove("active"));
        sliderContentChildren.forEach((control) =>
          control.classList.remove("active")
        );
        control.classList.add("active");
        sliderContentChildren[index].classList.add("active");
      });
    });
  }

  const solutionSectionElems = {
    controlElem: ".slider button",
    contentElem: ".section-solutions-content",
  };
  const brandSectionElems = {
    controlElem: ".tab .page-link",
    contentElem: ".brand-content",
  };

  addSliderNavigation(solutionSectionElems);
  addSliderNavigation(brandSectionElems);
}
