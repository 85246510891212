function hideElement(element) {
  element.setAttribute("aria-expanded", "false");
}

function toggleElement(element) {
  const expanded = element.getAttribute("aria-expanded");

  element.setAttribute("aria-expanded", expanded === "true" ? "false" : true);
}

export function handleDropdownToggle() {
  const dropdowns = Array.from(document.querySelectorAll(".dropdown"));
  const dropdownPanels = Array.from(
    document.querySelectorAll(".dropdown-content")
  );

  document.addEventListener("click", (event) => {
    const target = event.target as HTMLElement;
    const clickedDropdown = dropdowns.find(
      (dropdown) => dropdown === target || dropdown.contains(target)
    );
    const clickedDropdownPanel = dropdownPanels.find(
      (dropdown) => dropdown === target || dropdown.contains(target)
    );

    if (clickedDropdownPanel) {
      hideElement(clickedDropdownPanel.parentElement);
      return;
    }

    if (clickedDropdown) {
      toggleElement(clickedDropdown);
      return;
    }

    dropdowns.forEach(hideElement);
  });
}
