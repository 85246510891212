import { handleMenuToggle } from "./handle-menu-toggle";
import { animateAnchorNavigation } from "./animate-anchor-navigation";
import { handleDropdownToggle } from "./handle-dropdown-toggle";
import { handleSliderNavigation } from "./handle-slider-navigation";
import { handleButtonGroupSelection } from "./handle-button-group-selection";
import { renderTable } from "./render-table";
import { renderDonateCards } from "./render-donate-cards";
import { handelCopyText } from "./handle-copy-text";
import { renderTableInquiry } from "./render-table-inquiry";

handleMenuToggle();
handleDropdownToggle();
animateAnchorNavigation();
handleSliderNavigation();
renderTable();
handleButtonGroupSelection();
renderDonateCards();
handelCopyText();
renderTableInquiry();
