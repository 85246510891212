const tableColumns = ["Товар", "Характеристики", "Кількість", "Ціна", "Сума"];

const tableItems = [
  {
    id: "1",
    image:
      "https://img.freepik.com/free-photo/white-cloud-blue-sky_74190-7709.jpg?w=2000",
    item: "Huile Vierge De Navette",
    characteristics: "1L",
    quantity: "20",
    price: "$750",
    summ: "$15,000",
  },
  {
    id: "2",
    image:
      "https://img.freepik.com/free-photo/white-cloud-blue-sky_74190-7709.jpg?w=2000",
    item: "Huile Vierge De ",
    characteristics: "1L",
    quantity: "20",
    price: "$750",
    summ: "$15,000",
  },
  {
    id: "3",
    image: "",
    item: "Huile ",
    characteristics: "1L",
    quantity: "20",
    price: "$750",
    summ: "$15,000",
  },
];

export function renderTableInquiry() {
  const tableElement = document.querySelector(".table.inquiry");
  const tableHeaderHtml = tableColumns
    .map((column) => `<div class="table-header-cell">${column}</div>`)
    .join("");
  const tableBodyHtml = tableItems
    .map(
      (item) => `
    <div class="table-cell cell-with-image">
      <img class="table-cell-text-accent image" src=${item.image}></img>
      <p class="table-cell-text-secondary">${item.item}</p>
    </div>
    <div class="table-cell">${item.characteristics}</div>
    <div class="table-cell">${item.quantity}</div>
    <div class="table-cell">${item.price}</div>
    <div class="table-cell summ">${item.summ}</div>
  `
    )
    .join("");

  if (!tableElement) {
    return;
  }

  tableElement.innerHTML = tableHeaderHtml.concat(tableBodyHtml);
}
