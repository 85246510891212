export function handleMenuToggle() {
  const triggerButton = document.querySelector(".menu-btn");
  const menuContainerElement = document.querySelector(".header__main");

  triggerButton.addEventListener("click", () => {
    const visibility = menuContainerElement.getAttribute("data-visible");

    if (visibility === "false") {
      menuContainerElement.setAttribute("data-visible", "true");
      triggerButton.setAttribute("aria-expanded", "true");
      return;
    }

    menuContainerElement.setAttribute("data-visible", "false");
    triggerButton.setAttribute("aria-expanded", "false");
  });
}
