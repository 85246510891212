export function handleButtonGroupSelection() {
  const buttonGroups = document.querySelectorAll(".button-group");

  buttonGroups.forEach((buttonGroup) => {
    const buttons = buttonGroup.querySelectorAll(".button");
    const formGroup = buttonGroup.closest(".form-group");

    buttonGroup.addEventListener("click", (event) => {
      const button = event.target as HTMLButtonElement;

      buttons.forEach((button) => {
        button.setAttribute("data-selected", "false");
      });
      button.setAttribute("data-selected", "true");
      formGroup.setAttribute(
        "data-selected",
        button.getAttribute("data-value")
      );
    });
  });
}
