import donateDestinationImageUrl0 from "../assets/img/donate-destination-1.png";
import donateDestinationImageUrl1 from "../assets/img/donate-destination-2.png";
import donateDestinationImageUrl2 from "../assets/img/donate-destination-3.png";

const imageUrlsByIndex = {
  0: donateDestinationImageUrl0,
  1: donateDestinationImageUrl1,
  2: donateDestinationImageUrl2,
};

interface DonationData {
  destination: string;
  title: string;
  description: string;
  imageUrl: string;
  currentSum: number;
  totalSum: number;
}

interface DonationDataViewModel extends DonationData {
  progress: number;
}

const mockData = Array.from({ length: 3 }, (...[, index]) => ({
  destination: "Лікарня, м. Херсон",
  title: "Збір на медичне обладнання",
  description: "Закупити 5300+ дронів для українського війська",
  imageUrl: imageUrlsByIndex[index],
  currentSum: 10279,
  totalSum: 30000,
})).map((item) => ({
  ...item,
  progress: item.currentSum / item.totalSum,
}));

const cardsHtmlString = mockData.map(prepareCardHtml).join("");

function prepareCardHtml(data: DonationDataViewModel): string {
  return `
      <div class="card">
        <div class="card-image-container">
          <p class="card-image-caption">
            ${data.destination}
          </p>
          <img src="${data.imageUrl}">
        </div>
    
        <h1 class="card-title">${data.title}</h1>
        <p class="card-description">
          ${data.destination}
        </p>
        <div class="card-progress-container">
          <p>
            <span class="card-progress-title-content card-progress-title-content--accent">₴ ${
              data.currentSum
            }</span>
            <span class="card-progress-title-content">/ ₴ ${
              data.totalSum
            }</span>
          </p>
          <div class="card-progress">
            <div class="card-progress-tile" style="--progress: ${
              data.progress * 100
            }%"></div>
          </div>
        </div>
        <a class="link-btn btn-primary-outline">Підтримати</a>
      </div>
    `;
}

export function renderDonateCards(): void {
  const cardsContainer = document.querySelector(".section-help .cards");
  if (cardsContainer) cardsContainer.innerHTML = cardsHtmlString;
}
