const tableColumns = [
  "Номер # / Дата",
  "Автор",
  "Адреса",
  "Отримувач",
  "Замовлення",
];

const tableItems = [
  {
    id: "Q000000025",
    date: "28.12.2022",
    author: "Лікарня",
    address: "м. Херсон",
    receiver: "Херсонський обласний центр служби крові",
    order: "Генератор",
  },
  {
    id: "Q000000025",
    date: "28.12.2022",
    author: "Лікарня",
    address: "м. Херсон",
    receiver: "Херсонська ОВА",
    order:
      "Послуги з передавання даних і повідомлень (електронні комунікаційні послуги)",
  },
  {
    id: "Q000000025",
    date: "28.12.2022",
    author: "Фонд",
    address: "м. Херсон",
    receiver: "Херсонська міська клінічна лікарня ім. О. С. Лучанського",
    order: "Акумулятори 60Ah, 100Ah, 140Ah (12V)",
  },
  {
    id: "Q000000025",
    date: "28.12.2022",
    author: "Лікарня",
    address: "м. Херсон",
    receiver: "Херсонська ОВА",
    order: "Генератор",
  },
  {
    id: "Q000000025",
    date: "28.12.2022",
    author: "Лікарня",
    address: "м. Херсон",
    receiver: "Бюро судово-медичної експертизи",
    order: "Акумулятори 60Ah, 100Ah, 140Ah (12V)",
  },
];

export function renderTable() {
  const tableElement = document.querySelector(".table.needs");
  const tableHeaderHtml = tableColumns
    .map((column) => `<div class="table-header-cell">${column}</div>`)
    .join("");
  const tableBodyHtml = tableItems
    .map(
      (item) => `
    <div class="table-cell">
      <p class="table-cell-text-accent">${item.id}</p>
      <p class="table-cell-text-secondary">${item.date}</p>
    </div>
    <div class="table-cell">${item.author}</div>
    <div class="table-cell">${item.address}</div>
    <div class="table-cell">${item.receiver}</div>
    <div class="table-cell">${item.order}</div>
  `
    )
    .join("");

  if (!tableElement) {
    return;
  }

  tableElement.innerHTML = tableHeaderHtml.concat(tableBodyHtml);
}
