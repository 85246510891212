export function animateAnchorNavigation() {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (event) {
      event.preventDefault();
      const targetHref = anchor.getAttribute("href");

      if (targetHref === "#") {
        return;
      }

      const targetElement = document.querySelector(anchor.getAttribute("href"));

      if (!targetElement) {
        return;
      }

      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    });
  });
}
